<template>
  <main class="icon">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59091 19.0114V0.261363H11.7727V19.0114H8.59091ZM0.806818 11.2273V8.04545H19.5568V11.2273H0.806818Z"
        :fill="fill"
      />
    </svg>
  </main>
</template>

<script>
export default {
  props: ["fill"],
};
</script>

<style scoped>
.icon:hover path {
  filter: brightness(95%);
}
</style>